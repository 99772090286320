module.exports = {
  // eslint-disable-next-line no-loss-of-precision
  whiteListMintCount: 1,
  totalGas: 300000,
  MaxMintCounts: 1000,
  MINTCONTRACT_ADDRESS: '0x75CDC7Ae5287Dc613b8823213D8eE356C5F24f98',
  BUNNYTOKEN_ADDRESS: '0x1aa5282692398c078e71Fb3e4A85660d1BF8F586',
  BINKYTOKEN_ADDRESS: '0x5E0F9BeFbF2F379CbCa90698AB01B00250362A21',
  BurnBunny: 5000,
  BINKYMINT: 3000000,
}
